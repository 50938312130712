html.theme-transition,
html.theme-transition *,
html.theme-transition *:before,
html.theme-transition *:after {
  transition: all 750ms !important;
  transition-delay: 0 !important;
}

#root {
  z-index: 1;
  width: 100vw;
  overflow-x: hidden;
  position: relative;
  .bubble-pink,
  .bubble-purple {
    position: absolute;
  }
  .bubble-pink {
    z-index: 1;
    height: 400px;
    width: 400px;
    border-radius: 100%;
    background-color: $lightpink;
    top: -3%;
    right: -20%;
    @media (min-width: 576px) {
      height: 500px;
      width: 500px;
      top: -5%;
      right: -25%;
    }
    @media (min-width: 992px) {
      top: -5%;
      right: -20%;
    }
    @media (min-width: 1200px) {
      top: -5%;
      right: -10%;
    }
  }
}

body {
  overflow-x: hidden;
}

a,
p,
h1,
h2,
h3,
h4,
h5 {
  z-index: 3;
  font-family: "Karla, sans-serif";
  text-decoration: none;
  letter-spacing: -0.2px;
  &:hover {
    text-decoration: none;
  }
}

h1 {
  font-family: "Spacegrotesk, sans-serif";
  line-height: 1.05;
  letter-spacing: -0.03em;
  font-size: 2.5rem;
}

a {
  line-height: 20px;
}
$fuschia: #ff0081;
$button-bg: #07beb8;
$button-text-color: #fff;
$baby-blue: #f8faff;

.bubbles-button {
  display: inline-block;
  font-size: 1.1em;
  padding: 0.5em 1em;
  //   font-weight: 700;
  transform: scale(0.8);
  -webkit-appearance: none;
  appearance: none;
  background-color: $purple;
  color: $button-text-color;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  position: relative;
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
  a {
    color: white;
    letter-spacing: 0.1em;
    text-decoration: none;
  }
  &:focus {
    outline: 0;
  }
  &:before,
  &:after {
    position: absolute;
    content: "";
    display: block;
    width: 140%;
    height: 100%;
    left: -20%;
    z-index: -1000;
    transition: all ease-in-out 0.5s;
    background-repeat: no-repeat;
  }
  &:before {
    display: none;
    top: -75%;
    background-image: radial-gradient(circle, $button-bg 20%, transparent 20%), radial-gradient(circle, transparent 20%, $button-bg 20%, transparent 30%),
      radial-gradient(circle, $button-bg 20%, transparent 20%), radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, $button-bg 15%, transparent 20%), radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, $button-bg 20%, transparent 20%), radial-gradient(circle, $button-bg 20%, transparent 20%), radial-gradient(circle, $button-bg 20%, transparent 20%);
    background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
    //background-position: 0% 80%, -5% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 85% 30%;
  }
  &:after {
    display: none;
    bottom: -75%;
    background-image: radial-gradient(circle, $button-bg 20%, transparent 20%), radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, $button-bg 15%, transparent 20%), radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, $button-bg 20%, transparent 20%), radial-gradient(circle, $button-bg 20%, transparent 20%), radial-gradient(circle, $button-bg 20%, transparent 20%);
    background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
    //background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }
  &:active {
    transform: scale(0.6);
    background-color: darken($purple, 5%);
    box-shadow: 0 2px 25px rgba(184, 104, 145, 0.2);
  }
  &.animate {
    &:before {
      display: block;
      animation: topBubbles ease-in-out 0.75s forwards;
    }
    &:after {
      display: block;
      animation: bottomBubbles ease-in-out 0.75s forwards;
    }
  }
}

@keyframes topBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }
  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
  }
  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

@keyframes bottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
  }
  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
  }
  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}
