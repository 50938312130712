// Primary - Blush
$primary: #d96a8e;
// Secondary - Cyclamen
$secondary: #f27ba7;
// Blue Cadet
$cadet: #2d3047;
// Dark Sky
$darksky: #93b7be;
$bone: #f9f6f1;
$blueyonder: #9cadce;
// Blubbles
$lightpink: #edd4dd;
$purple: #6d2e46;
// Font sizes
// Navigation
$nav-font-size: 16px;
:root {
  --primary-color: #302ae6;
  --secondary-color: #536390;
  --font-color: #424242;
  --bg-color: #f9f6f1;
  --heading-color: #292922;
  // Navigation
  --nav-bg-color: #2d3047;
  --navlink-color: #6d2e46;
  --navlink-color-hover: #07beb8;
  // Icon theme switcher
  --theme-switcher-color: black;
  // Home
  --home-bg-color: #f9f6f1;
  --home-text: #6d2e46;
  --home-text-colored: #07beb8;
  --home-font-size: 1.2rem;
  --home-font-size-big-desktop: 68px;
  --home-font-size-big-mobile: 45px;
  --home-font-size-big-tablet-sm: 55px;
  --home-font-size-big-tablet-lg: 60px;
  --home-font-size-big-widescreen: 68px;
  // Portfolio
  --portfolio-font-size: 1rem;
}

[data-theme="dark"] {
  --primary-color: #9a97f3;
  --secondary-color: #818cab;
  --font-color: #e1e1ff;
  --bg-color: #161625;
  --heading-color: #818cab;
  // Navigation
  --nav-bg-color: #2d3047;
  --navlink-color: #ece2d0;
  // Icon theme switcher
  --theme-switcher-color: yellow;
  // Home
  --home-bg-color: black;
  --home-text: #ece2d0;
  --home-font-size: 2rem;
  // Portfolio
  --portfolio-font-size: 1.3rem;
  --portfolio-h1-size: 2rem;
  --portfolio-h4-color: #93b7be;
}
