@import "./variables";

.nav-wrapper {
  z-index: 999999;
  position: relative;
  height: 100%;
  .container {
    justify-content: space-around;
  }
  .navbar-brand {
    img {
      height: 50px;
    }
  }
  .navbar-nav {
    .nav-item {
      margin: 0 20px;
      a {
        font-size: $nav-font-size;
        color: var(--navlink-color);
        transition: all 300ms ease;
        font-weight: 700;
        &:hover {
          color: var(--navlink-color-hover);
          transition: all 300ms ease;
        }
      }
    }
  }
  .navbar-collapse {
    .navbar-nav {
      margin: auto;
    }
    @media (max-width: 992px) {
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
      background-color: var(--home-bg-color);
      .navbar-nav {
        justify-content: center;
        .nav-item {
          padding-top: 15px;
        }
      }
      .links-nav {
        padding-top: 70px;
        .nav-item {
          margin: auto;
        }
      }
      .icons-wrapper {
        flex-direction: row;
        justify-self: center;
        padding-bottom: 30px;
      }
    }
  }
  .navbar-toggler {
    z-index: 9;
    border: 1px solid #d96a8e;
    height: 50px;
    border-radius: 10px;
    background-color: #6d2e46;
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(242, 123, 167, 1)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
    &.collapsed {
      background-color: rgba(249, 246, 241, 1);
      .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(242, 123, 167, 1)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
      }
    }
  }
  .icon-style {
    font-size: 20px;
    color: var(--theme-switcher-color);
    &.rotating {
      -webkit-animation: rotating 4s linear infinite;
      -moz-animation: rotating 4s linear infinite;
      -ms-animation: rotating 4s linear infinite;
      -o-animation: rotating 4s linear infinite;
      animation: rotating 4s linear infinite;
    }
  }
  .light-mode-icon:hover {
    cursor: pointer;
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
