.title-wrapper {
  h4,
  h1 {
    z-index: 99;
    text-align: center;
  }
}

.about {
  justify-content: center;
  .content-about-wrap {
    margin-top: 80px;
  }
  .about-two {
    margin-top: 20px;
  }
  .skill-wrapper {
    z-index: 5;
    background-color: rgba(249, 246, 241, 0.2);
    padding: 30px;
    transition: all 500ms ease;
    border-radius: 30px;
    height: 250px;
    @media (min-width: 1020px) {
      padding: 50px;
    }
    @media (min-width: 768px) {
      height: 300px;
    }
    @media (min-width: 1200px) {
      height: 250px;
    }
    &:hover {
      background-color: rgba(249, 246, 241, 1);
      transition: all 500ms ease;
      box-shadow: 11px 11px 70px 30px rgba(243, 237, 226, 1.8);
    }
    &.frontend,
    &.science {
      margin-bottom: 20px;
      @media (min-width: 768px) {
        margin-bottom: 20px;
      }
    }
    .box-skill-container {
      .skill-title {
        display: flex;
        align-items: center;
        width: max-content;
        h4 {
          width: min-content;
          font-weight: 700;
          // color: var(--home-text);
          margin: 0;
          padding-left: 20px;
        }
        .icon-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 80px;
          width: 80px;
          // border: 1px solid #07BEB8;
          border-radius: 20px;
          &.frontend {
            background-color: #eac8ae;
            svg {
              color: #cb793a;
              font-size: 50px;
            }
          }
          &.graphics {
            background-color: #fcd9dc;
            svg {
              color: #f45b69;
            }
          }
          &.datascience {
            background-color: #d8e9fd;
            svg {
              color: #67aaf9;
            }
          }
          &.game {
            background-color: #d8dfce;
            svg {
              color: #566340;
            }
          }
        }
        svg {
          font-size: 50px;
        }
      }
      .text-about {
        padding-top: 30px;
      }
    }
  }
}

.python-icon,
.unity-icon {
  position: absolute;
  font-size: 50px;
  opacity: 0.8;
}
.python-icon {
  bottom: 45%;
  right: 0;
  transform: rotate(25deg);
}
.unity-icon {
  bottom: -10%;
  left: 5%;
  //   transform: rotate(-15deg);
}
@media (min-width: 768px) {
  .python-icon,
  .unity-icon {
    font-size: 80px;
  }
  .python-icon {
    bottom: 10%;
    right: 0;
    transform: rotate(25deg);
  }
  .unity-icon {
    bottom: 0;
    left: -10%;
  }
}
@media (min-width: 992px) {
  .python-icon,
  .unity-icon {
    font-size: 80px;
  }
  .python-icon {
    bottom: 5%;
    right: 20%;
    transform: rotate(25deg);
  }
  .unity-icon {
    bottom: -35%;
    left: 20%;
  }
}
.react-icon,
.vuejs-icon,
.html-icon,
.unity-icon {
  opacity: 0.4;
  position: absolute;
  font-size: 40px;
}
.react-icon {
  top: -20%;
  left: 15%;
}
.vuejs-icon {
  right: 0;
  top: 40%;
}
.html-icon {
  top: 95%;
  left: 65%;
}
@media (min-width: 768px) {
  .react-icon,
  .vuejs-icon,
  .html-icon,
  .unity-icon {
    opacity: 0.8;
    font-size: 80px;
  }
  .react-icon {
    top: -20%;
    left: 65%;
  }
  .vuejs-icon {
    right: -5%;
    top: -5%;
  }
  .html-icon {
    top: 50%;
    left: -8%;
  }
}
@media (min-width: 992px) {
  .react-icon {
    top: -35%;
    left: 60%;
  }
  .vuejs-icon {
    right: 5%;
    top: -20%;
  }
  .html-icon {
    top: -20%;
    left: 3%;
  }
}

.react-icon {
  color: #b4bdcf;
}
.vuejs-icon {
  color: #76e7cd;
}
.html-icon {
  color: #e28413;
}
.python-icon {
  color: #e9eb87;
}

.my-pic {
  border-radius: 30px;
  margin-bottom: 10px;
}
