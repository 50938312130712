.contact {
  margin-top: 100px;
  justify-content: center;
  padding-top: 50px;
  .form-outer-wrapper {
    padding-top: 50px;
    padding-bottom: 30px;
    background-color: $lightpink;
    border-radius: 30px;
    position: relative;
  }
}

input {
  font-family: "Karla, sans-serif";
  border: none !important;
  padding: 5px 10px !important;
  height: 30px !important;
  &:focus {
    box-shadow: none !important;
  }
}

select {
  height: 30px !important;
  padding: 0 5px !important;
  border-radius: 10px !important;
  border: 1px solid $purple !important;
  &:focus {
    box-shadow: none !important;
  }
}

.form-row {
  flex-wrap: nowrap;
  label {
    margin-bottom: 0;
  }
}

.contact-form {
  padding-top: 30px;
  width: 80%;
  // height: 610px;
  color: $purple;
  margin: auto;
  @media (min-width: 992px) {
    width: 50%;
  }
}

.form-label {
  margin-left: 3px;
  width: 40%;
}

.form-control {
  border-radius: 10px;
  &:focus {
    box-shadow: none !important;
  }
  &.error {
    border: 1px solid red !important;
  }
}

textarea {
  border: 0 !important;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  input {
    margin-right: 10px;
  }
}

.form-control-checkbox {
  width: auto;
}

.checkbox-message {
  font-size: 13px;
  &.error {
    color: red;
  }
}

.modal-content {
  border-radius: 20px;
  border: 1px solid $purple;
  .modal-header {
    border-radius: 20px 20px 0 0;
    overflow: hidden;
    background-color: $purple;
    color: white;
    button {
      span {
        color: white;
      }
    }
  }
}

.contact-background-icon {
  position: absolute;
  right: 5%;
  top: 2%;
  opacity: 0.5;
  color: $purple;
  font-size: 80px;
  @media (min-width: 576px) {
    font-size: 100px !important;
  }
  @media (min-width: 768px) {
    font-size: 150px !important;
  }
}
