.carousel-control-next {
  right: 0;
  z-index: 999;
  top: -20%;
  @media (min-width: 768px) {
    right: -15%;
  }
  @media (min-width: 1024px) {
    right: -10%;
    top: 0%;
  }
  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23d96a8e' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
  }
}
.carousel-control-prev {
  left: 0;
  top: -20%;
  z-index: 999;
  @media (min-width: 768px) {
    left: -15%;
  }
  @media (min-width: 1024px) {
    left: -10%;
    top: 0%;
  }
  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23d96a8e' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
  }
}
.carousel-indicators {
  bottom: 15%;
  @media (min-width: 425px) {
    bottom: -5%;
  }
  @media (min-width: 524px) {
    bottom: -15%;
  }
  @media (min-width: 768px) {
    bottom: 10%;
  }
  @media (min-width: 1024px) {
    bottom: 0;
  }
  li {
    background-color: $primary;
  }
}

.desktop {
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
}

.mobile {
  display: block;
  @media (min-width: 768px) {
    display: none;
  }
}
.portfolio-wrapper {
  position: relative;
  min-height: 300px;
  @media (min-width: 768px) {
    min-height: 700px;
  }
  @media (min-width: 1200px) {
    min-height: 700px;
  }

  .square {
    height: 300px;
    width: 100%;
    background-color: #2e5e6d;
    position: absolute;
    // right: -25%;
    top: 0%;
    border-radius: 20px;
    @media (min-width: 425px) {
      // width: 400px;
      height: 400px;
      // right: -5%;
      top: -7%;
    }
    @media (min-width: 524px) {
      // width: 600px;
      height: 450px;
      // right: -20%;
      top: -2%;
    }
    @media (min-width: 768px) {
      height: 650px;
      width: 700px;
    }
    @media (min-width: 992px) {
      height: 750px;
      width: 900px;
      right: -10%;
    }
    @media (min-width: 1024px) {
      height: 750px;
      width: 900px;
    }
    @media (min-width: 1200px) {
      height: 900px;
      width: 1000px;
      right: 0%;
    }
  }
}
.carousel-inner {
  min-height: 350px;
  @media (min-width: 768px) {
    min-height: 700px;
  }
  @media (min-width: 1200px) {
    min-height: 850px;
  }
}
.portfolio {
  // margin-top: 80px;
  // position: relative;
  .content-outer-wrap {
    margin: 20px auto;
    z-index: 5;
    height: 100%;
    .img-wrapper {
      border-radius: 20px;
      overflow: hidden;
      position: relative;
      .img-wrapper-content {
        display: flex;
        justify-content: center;
        &:hover {
          .work-description {
            opacity: 1;
            transition: all 300ms ease-in-out;
            transform: translateY(-10px);
          }
        }
        .work-description {
          transition: all 500ms ease-in-out;
          opacity: 0;
          position: absolute;
          top: 15%;
          width: 85%;
          height: 85%;
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: rgba(0, 0, 0, 0.8);
          h3 {
            // font-weight: 700;
            letter-spacing: 0.2px;
            font-size: 18px;
            color: white;
            margin-bottom: 10px;
            @media (min-width: 768px) {
              font-size: 18px;
            }
            @media (min-width: 768px) {
              font-size: 22px;
            }
          }
          p {
            text-align: center;
            color: white;
            width: 90%;
          }
        }
      }
    }
  }
}
.pixel-art-row {
  .pixel-art-col {
    margin: 10px auto;
    img {
      transition: all 300ms ease-in-out;
      border-radius: 20px;
      &:hover {
        transition: all 300ms ease-in-out;
        transform: scale(2);
        box-shadow: 5px 5px 30px rgba(243, 237, 226, 1.8);
      }
    }
  }
}
