.content-outer-wrapper {
  background-color: var(--home-bg-color);
  //   padding: 0;
}

.home {
  padding: 50px 0;
  .text-home-wrapper {
    align-self: center;
    padding-left: 20px;
    padding-right: 20px;
    z-index: 5;
    .big-text-wrap {
      flex-wrap: wrap;
      display: flex;
      .colored {
        margin-left: 10px;
        color: var(--home-text-colored);
      }
    }
    .home-text {
      font-size: var(--home-font-size);
      color: var(--home-text);
      &.big {
        font-size: var(--home-font-size-big-mobile);
        font-weight: 700;
        @media (min-width: 576px) {
          font-size: var(--home-font-size-big-tablet-sm);
        }
        @media (min-width: 768px) {
          font-size: var(--home-font-size-big-tablet-lg);
        }
      }
    }
  }
  .hero-outer-wrap {
    @media (max-width: 992px) {
      padding-top: 50px;
      padding-right: 50px;
    }
    height: 100%;
    .hero-wrapper {
      height: 350px;
      @media (min-width: 576px) {
        height: 500px;
      }
      @media (min-width: 768px) {
        height: 600px;
      }
      justify-self: center;
      align-self: center;
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      transform-style: preserve-3d;
      z-index: 2;
      overflow: hidden;
      position: relative;

      .bubble-purple {
        overflow: hidden;
        border-radius: 100% 0px 100% 100%;
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        background-color: $purple;
        height: 300px;
        width: 300px;
        @media (min-width: 576px) {
          height: 500px;
          width: 500px;
        }
        @media (min-width: 768px) {
          height: 600px;
          width: 600px;
        }
        @media (min-width: 1020px) {
          height: 450px;
          width: 450px;
        }
        @media (min-width: 1300px) {
          height: 500px;
          width: 500px;
        }
        img {
          padding-top: 30px;
          border-radius: 100%;
        }
      }
    }
  }
}

.scroll-top-wrapper {
  width: 60px;
  height: 60px;
  border: 2px solid $purple;
  background-color: $purple;

  position: fixed;
  bottom: 20px;
  right: 3%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  z-index: 1000;
  &:hover {
    cursor: pointer;
    .scrollTop {
      transition: all 200ms ease;
      animation: scroll-top 0.8s linear infinite;
    }
  }
}
.scrollTop {
  transition: all 200ms ease;
  color: $lightpink;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes scroll-top {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateY(-10px);
  }
}

.dots-wrapper {
  position: absolute;
  height: 100%;
  z-index: 3;
  top: 0;
  left: 10%;
  &.contact {
    top: -30%;
    left: -5%;
    transform: scale(0.9);
    @media (min-width: 768px) {
      top: -25%;
      left: -5%;
      transform: scale(1);
    }
  }
  &.pixel-art {
    left: 60%;
    top: 85%;
    transform: scale(0.9);
    @media (min-width: 576px) {
      top: -10%;
      left: 75%;
      transform: scale(1);
    }
  }
  &.frontend {
    opacity: 0.8;
    top: 110%;
    left: 50%;

    transform: scale(0.7);
    .dot {
      background-color: $lightpink !important;
    }
    @media (min-width: 356px) {
      top: 120%;
      left: 55%;
    }
    @media (min-width: 476px) {
      top: 120%;
      left: 70%;
    }
    @media (min-width: 576px) {
      top: 90%;
      left: 75%;
      transform: scale(1);
    }
    @media (min-width: 922px) {
      top: 80%;
      left: 75%;
    }
  }
  &.skills {
    left: 60%;
    top: -2%;
    transform: scale(0.9);
    .dot {
      background-color: $purple !important;
    }

    @media (min-width: 576px) {
      left: 75%;
      top: 4%;
      transform: scale(1);
    }
    @media (min-width: 768px) {
      left: 75%;
      top: 1%;
    }
  }

  .dot {
    margin: 10px;
    height: 5px;
    width: 5px;
    background-color: #07beb8;
    border-radius: 100%;
  }
}
